/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li",
    p: "p",
    h3: "h3",
    strong: "strong",
    h4: "h4",
    pre: "pre",
    code: "code",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Basics of Functional Programming"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "immutable data structures"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.h2, null, "first class functions"), "\n"), "\n", React.createElement(_components.li, null, "frameworks like React and Angular have improved performance by using immutable data structures."), "\n"), "\n", React.createElement(_components.p, null, "Writing real world code with solely pure functions is impractical if not impossible. Therefore, the goals is to aim for 80/20 rule having 80% pure function for a given project."), "\n", React.createElement(_components.h3, null, "What functional programming isn't"), "\n", React.createElement(_components.h3, null, "Pure Functions"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Definition")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Referential transparency"), "\n: The function always gives the same return value for the same argument.\n: The function cannot depend on mutable state"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Side-effect free"), "\n: The function cannot cause side effects.\n: Side effects may include I/O (console or lgging), modifying a mutable object, reassigning a variable"), "\n", React.createElement(_components.h4, null, "Examples"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "function multiply(a, b) {\n  return a * b\n}\n")), "\n", React.createElement(_components.h4, null, "Benefits of Pure Functions"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "easier to debug because they don't depend on mutable state"), "\n", React.createElement(_components.li, null, "return value can be cached which reduces recomputing"), "\n", React.createElement(_components.li, null, "easier to test because there are on dependencies that need to be mocked"), "\n"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "🗒️ if a function has no return value, then it's a no-op or it causing a side effect. Similarly, if a function is called but its return value is not used it's probably functionally handling a side effect and is therefore impure."), "\n"), "\n", React.createElement(_components.h4, null, "Example - Can Ride"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Impure Function")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "let heightRequirement = 46\n\nfunction canRide(height) {\n  return height >= heightRequirement\n}\n\n// Every half second, set heightRequirement to a random number between 0 and 200.\nsetInterval(() => (heightRequirement = Math.floor(Math.random() * 201)), 500)\n\nconst mySonsHeight = 47\n\n// Every half second, check if my son can ride.\n// Sometimes it will be true and sometimes it will be false.\nsetInterval(() => console.log(canRide(mySonsHeight)), 500)\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Rewrite as Pure")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const heightRequirement = 46\n\nfunction canRide(height) {\n  return height >= heightRequirement\n}\n")), "\n", React.createElement(_components.h3, null, "Functional Programing Array Methods"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "\"use strict\"\n\nconst arr = Object.freeze([4, 5, 6])\n\n// push()\nconst arrB = arr.concat(7, 8, 9)\n// or\n// const arrB = [...arr, 7,8,9]\n\n// pop()\nconst arrC = arr.slice(0, -1)\n\n// unshift(1,2,3)\nconst arrD = [1, 2, 3].concat(arr)\n\n// shift()\nconst arrE = arr.slice(1)\n\n// sort()\nconst arrF = R.sort(compareFunction, arr) // R = Ramda\n\n// reverse()\nconst arrG = R.reverse(arr) // R = Ramda\n\n// copyWithin()\n\n// fill()\n\n// splice()\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
